import { Auth, Hub } from "aws-amplify";
import React, { useEffect } from "react";
import { axiosClient } from "utils";

import type { HubCapsule } from "@aws-amplify/core/lib/Hub";

interface ProductsContextType {
  products: Product[];
  setProducts: (products: any) => void;
}

export const ProductsContext = React.createContext<ProductsContextType>({
  products: [],
  setProducts: (products: Product[]) => {
    console.log("Not loaded yet!");
  },
});

export const ProductsContextProvider: React.FC = ({ children }) => {
  const setProducts = (products: Product[]) => {
    setState({ ...state, products });
  };

  const [state, setState] = React.useState<ProductsContextType>({
    products: [],
    setProducts,
  });

  useEffect(() => {
    const updateEntities = async (capsule?: HubCapsule) => {
      if (capsule === undefined || capsule.payload.event === "signIn") {
        try {
          const res = await axiosClient.get("/products");
          setProducts(res.data);
        } catch (err) {
          setProducts([]);
        }
      }
    };

    Auth.currentAuthenticatedUser().then((user) => {
      user && updateEntities();
    });

    Hub.listen("auth", updateEntities);
    return () => Hub.remove("auth", updateEntities);
  }, []);

  return (
    <ProductsContext.Provider value={state}>
      {children}
    </ProductsContext.Provider>
  );
};
