import React, { ReactElement } from "react";

import { BleedingLocationsContextProvider } from "./BleedingLocationsContext";
import { DosageContextProvider } from "./DosageContext";
import { DosageUnitsContextProvider } from "./DosageUnitsContext";
import { UserDataContextProvider } from "./UserDataContext";
import { ProductsContextProvider } from "./ProductsContext";

interface Props {
  children: React.ReactNode;
}

export default function ContextManager({ children }: Props): ReactElement {
  return (
    <UserDataContextProvider>
      <ProductsContextProvider>
        <DosageContextProvider>
          <BleedingLocationsContextProvider>
            <DosageUnitsContextProvider>{children}</DosageUnitsContextProvider>
          </BleedingLocationsContextProvider>
        </DosageContextProvider>
      </ProductsContextProvider>
    </UserDataContextProvider>
  );
}
