import { Auth, Hub } from "aws-amplify";
import React, { useEffect } from "react";
import { axiosClient } from "utils";

interface DosageContextType {
  dosages: Dosage[];
  setDosages: (dosages: any) => void;
}

export const DosageContext = React.createContext<DosageContextType>({
  dosages: [],
  setDosages: (dosages: Dosage[]) => {
    console.log("Not loaded yet!");
  },
});

export const DosageContextProvider: React.FC = ({ children }) => {
  const setDosages = (dosages: Dosage[]) => {
    setState({ ...state, dosages });
  };

  const [state, setState] = React.useState<DosageContextType>({
    dosages: [],
    setDosages,
  });

  useEffect(() => {
    const updateEntities = async () => {
      try {
        const res = await axiosClient.get("/dosage");
        setDosages(res.data);
      } catch (err) {
        setDosages([]);
      }
    };

    Auth.currentAuthenticatedUser().then((user) => {
      user && updateEntities();
    });

    Hub.listen("auth", updateEntities);
    return () => Hub.remove("auth", updateEntities);
  }, []);

  return (
    <DosageContext.Provider value={state}>{children}</DosageContext.Provider>
  );
};
