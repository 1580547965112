import { Button, Form, Input, message, Typography } from "antd";
import { FormInstance } from "antd/lib/form";
import { ConfirmSignUp } from "aws-amplify-react";
import React, { ChangeEvent, FormEvent } from "react";
import Helmet from "react-helmet";

import AuthLayout from "../layout/AuthLayout/AuthLayout";

export class CustomConfirmSignUp extends ConfirmSignUp {
  _validAuthStates = ["confirmSignUp"];

  formRef = React.createRef<FormInstance>();
  loading = false;

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    this.inputs.code = val;

    this.handleInputChange(e);
  };

  handleSubmit = (e: FormEvent) => {
    e.persist();
    e.preventDefault();
    this.formRef.current!.validateFields().then((values) => {
      super.confirm();
      this.loading = true;
    });
  };

  showComponent() {
    return (
      <AuthLayout>
        <Helmet>
          <title>Rejestracja | Profarmps.pl</title>
        </Helmet>
        <Typography.Title level={4} style={{ textAlign: "center" }}>
          Potwierdzenie adresu e-mail
        </Typography.Title>
        <Typography.Paragraph type="secondary">
          Na Twój adres e-mail wysłaliśmy kod weryfikacyjny - wpisz go poniżej,
          by potwierdzić, że adres należy do Ciebie.
        </Typography.Paragraph>
        <Form
          onSubmitCapture={this.handleSubmit}
          ref={this.formRef}
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          hideRequiredMark
        >
          <Form.Item
            name="code"
            label="Kod weryfikacyjny"
            rules={[
              {
                required: true,
                message: "Kod jest wymagany",
              },
              {
                whitespace: true,
                message: "Kod jest wymagany",
              },
            ]}
          >
            <Input
              id="code"
              key="code"
              name="code"
              onChange={this.handleInputChange}
              placeholder="Wpisz otrzymany kod"
            />
          </Form.Item>
          <Button
            onClick={(e) => {
              super.resend();
              message.info("Wysłano ponownie kod.");
            }}
            style={{ marginTop: 10 }}
            type="link"
          >
            Wyślij ponownie kod
          </Button>
          <Button
            onClick={this.handleSubmit}
            loading={this.loading}
            type="primary"
            htmlType="submit"
            style={{ width: "100%", height: 40, marginTop: 20 }}
          >
            Potwierdź
          </Button>
        </Form>
      </AuthLayout>
    );
  }
}
