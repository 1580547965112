import { Button, Form, Input, Typography } from "antd";
import { FormInstance } from "antd/lib/form";
import { SignUp, ForgotPassword } from "aws-amplify-react";
import React, { FormEvent, ChangeEvent } from "react";
import Helmet from "react-helmet";

import AuthLayout from "../layout/AuthLayout/AuthLayout";
import InputMask from "antd-mask-input";

export class CustomForgotPassword extends ForgotPassword {
  _validAuthStates = ["forgotPassword"];

  formRef1 = React.createRef<FormInstance>();
  formRef2 = React.createRef<FormInstance>();

  sendCode = (e: FormEvent) => {
    e.persist();
    e.preventDefault();
    this.send();
  };

  submitNewPassword = (e: FormEvent) => {
    e.persist();
    e.preventDefault();
    this.submit();
  };

  Form1 = () => (
    <Form
      onSubmitCapture={this.sendCode}
      ref={this.formRef1}
      wrapperCol={{ span: 24 }}
      labelCol={{ span: 24 }}
      hideRequiredMark
    >
      <Typography.Paragraph type="secondary">
        Zapomniałeś hasło? Nic straconego - wpisz poniżej adres e-mail, którym
        logujesz się do aplikacji. Pomożemy Ci ustawić nowe hasło
      </Typography.Paragraph>
      <Form.Item
        name="username"
        label="E-mail"
        rules={[
          { type: "email", message: "To nie jest poprawny e-mail." },
          {
            required: true,
            message: "To pole jest wymagane.",
          },
          {
            whitespace: true,
            message: "To pole jest wymagane.",
          },
        ]}
      >
        <Input
          id="username"
          key="username"
          name="username"
          onChange={this.handleInputChange}
          placeholder="Wpisz adres e-mail, którego używasz do logowania"
        />
      </Form.Item>
      <Button
        onClick={this.sendCode}
        type="primary"
        htmlType="submit"
        style={{ width: "100%", height: 40, marginTop: 20 }}
      >
        Wyślij kod
      </Button>
      <Button
        onClick={(e) => {
          this.changeState("signIn");
        }}
        style={{ width: "100%", marginTop: 10 }}
        type="link"
      >
        Powrót do logowania
      </Button>
    </Form>
  );

  Form2 = () => (
    <Form
      onSubmitCapture={this.sendCode}
      ref={this.formRef1}
      wrapperCol={{ span: 24 }}
      labelCol={{ span: 24 }}
      hideRequiredMark
    >
      <Typography.Paragraph type="secondary">
        Wysłaliśmy do Ciebie e-maila z kodem weryfikacyjnym, żeby potwierdzić,
        że to faktycznie Ty chcesz zmienić hasło. Wpisz go poniżej i ustaw nowe
        hasło.
      </Typography.Paragraph>
      <Form.Item
        name="code"
        label="Kod weryfikacyjny"
        hasFeedback
        rules={[
          {
            required: true,
            message: "Kod jest wymagany.",
          },
          {
            whitespace: true,
            message: "Kod jest wymagany.",
          },
        ]}
      >
        <Input
          id="code"
          key="code"
          name="code"
          placeholder="Wpisz kod z maila"
          onChange={this.handleInputChange}
        />
      </Form.Item>
      <Form.Item
        name="password"
        label="Nowe hasło"
        hasFeedback
        rules={[
          {
            required: true,
            message: "To pole jest wymagane.",
          },
          {
            whitespace: true,
            message: "To pole jest wymagane.",
          },
          {
            pattern: /\d/g,
            message: "Hasło musi zawierać cyfrę",
          },

          {
            pattern: /.{8,}/g,
            message: "Hasło musi mieć co najmniej 8 znaków",
          },
          {
            pattern: /[A-ZŻŹĆĄŚĘŁÓŃ]/,
            message: "Hasło musi zawierać wielką literę",
          },
          {
            pattern: /[a-zżźćąśęłóń]/,
            message: "Hasło musi zawierać małą literę",
          },
        ]}
      >
        <Input.Password
          id="password"
          key="password"
          name="password"
          onChange={this.handleInputChange}
          placeholder="Wpisz nowe hasło"
        />
      </Form.Item>
      <Form.Item
        name="confirm_password"
        label="Powtórz hasło"
        hasFeedback
        rules={[
          {
            required: true,
            message: "Hasło jest wymagane.",
          },
          {
            validator: (rule, value) => {
              return !value || value === this.inputs.password
                ? Promise.resolve()
                : Promise.reject("Hasła nie są takie same");
            },
          },
        ]}
      >
        <Input.Password
          id="confirm_password"
          key="confirm_password"
          name="confirm_password"
          placeholder="Wpisz ponownie hasło"
        />
      </Form.Item>
      <Button
        onClick={this.submitNewPassword}
        type="primary"
        htmlType="submit"
        style={{ width: "100%", height: 40, marginTop: 20 }}
      >
        Ustaw nowe hasło
      </Button>
    </Form>
  );

  showComponent() {
    return (
      <AuthLayout>
        <Helmet>
          <title>Reset hasła | Profarmps.pl</title>
        </Helmet>
        <Typography.Title level={4} style={{ textAlign: "center" }}>
          Reset hasła
        </Typography.Title>
        {this.state.delivery ? <this.Form2 /> : <this.Form1 />}
      </AuthLayout>
    );
  }
}
