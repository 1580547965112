import { Auth, Hub } from "aws-amplify";
import React, { useEffect } from "react";
import { axiosClient } from "utils";

interface BleedingLocationsContextType {
  bleedingLocations: BleedingLocation[];
  setBleedingLocations: (bleedingLocations: any) => void;
}

export const BleedingLocationsContext = React.createContext<
  BleedingLocationsContextType
>({
  bleedingLocations: [],
  setBleedingLocations: (bleedingLocations: BleedingLocation[]) => {
    console.log("Not loaded yet!");
  },
});

export const BleedingLocationsContextProvider: React.FC = ({ children }) => {
  const setBleedingLocations = (bleedingLocations: BleedingLocation[]) => {
    setState({ ...state, bleedingLocations });
  };

  const [state, setState] = React.useState<BleedingLocationsContextType>({
    bleedingLocations: [],
    setBleedingLocations,
  });

  useEffect(() => {
    const updateEntities = async () => {
      try {
        const res = await axiosClient.get("/locations");
        setBleedingLocations(res.data);
      } catch (err) {
        setBleedingLocations([]);
      }
    };

    Auth.currentAuthenticatedUser().then((user) => {
      user && updateEntities();
    });

    Hub.listen("auth", updateEntities);
    return () => Hub.remove("auth", updateEntities);
  }, []);

  return (
    <BleedingLocationsContext.Provider value={state}>
      {children}
    </BleedingLocationsContext.Provider>
  );
};
