import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

import styles from "./LoadingPlaceholder.module.scss";

interface Props {
  children?: never;
  minHeight?: number;
}

const LoadingPlaceholder: React.FC<Props> = ({ minHeight }) => (
  <div
    className={styles["loading"]}
    {...(minHeight && { style: { minHeight } })}
  >
    <Spin size="large" indicator={<LoadingOutlined />} />
  </div>
);

export default LoadingPlaceholder;
