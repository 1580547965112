import { ConfigProvider } from "antd";
import plLocale from "antd/es/locale/pl_PL";
import Amplify from "aws-amplify";
import { IAuthPieceProps } from "aws-amplify-react/lib-esm/Auth/AuthPiece";
import ContextManager from "contexts/ContextManager";
import moment from "moment";
import "moment/locale/pl";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { CustomAuthenticator } from "./aws";
import awsconfig from "./aws-config";
import useUserStatus from "./hooks/useUserStatus";
import AppLayout from "./layout/AppLayout/AppLayout";
import { Routes } from "./routes/routes";
import NavigatorManager from "components/NavigatorManager/NavigatorManager";

Amplify.configure(awsconfig);

const App: React.FC<IAuthPieceProps> = (props) => {
  moment.locale("pl");
  const user = useUserStatus();

  return props.authState === "signedIn" ? (
    <AppLayout>{user && <Routes />}</AppLayout>
  ) : null;
};

const WrappedApp: React.FC = () => (
  <NavigatorManager>
    <ContextManager>
      <ConfigProvider locale={plLocale}></ConfigProvider>
      <BrowserRouter>
        <CustomAuthenticator>
          <App />
        </CustomAuthenticator>
      </BrowserRouter>
    </ContextManager>
  </NavigatorManager>
);

export default WrappedApp;
