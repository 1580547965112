import React, { useEffect } from "react";
import { axiosClient } from "utils";
import { Hub, Auth } from "aws-amplify";
import type { HubCapsule } from "@aws-amplify/core/lib/Hub";

interface UserDataContextType {
  userData: UserData | null;
  setUserData: (userData: any) => void;
}

export const UserDataContext = React.createContext<UserDataContextType>({
  userData: null,
  setUserData: (userData: UserData | null) => {
    console.log("Not loaded yet!");
  },
});

export const UserDataContextProvider: React.FC = ({ children }) => {
  const setUserData = (userData: UserData | null) => {
    setState({ ...state, userData });
  };

  const [state, setState] = React.useState<UserDataContextType>({
    userData: null,
    setUserData,
  });

  useEffect(() => {
    const updateEntities = async (capsule?: HubCapsule) => {
      if (capsule === undefined || capsule.payload.event === "signIn") {
        try {
          const res = await axiosClient.get("/me");
          setUserData(res.data);
        } catch (err) {
          setUserData(null);
        }
      }
    };

    Auth.currentAuthenticatedUser().then((user) => {
      user && updateEntities();
    });
    Hub.listen("auth", updateEntities);
    return () => Hub.remove("auth", updateEntities);
  }, []);

  return (
    <UserDataContext.Provider value={state}>
      {children}
    </UserDataContext.Provider>
  );
};
