import { Button, Form, Input, Typography } from "antd";
import { FormInstance } from "antd/lib/form";
import { SignUp } from "aws-amplify-react";
import React, { FormEvent, ChangeEvent } from "react";
import Helmet from "react-helmet";

import AuthLayout from "../layout/AuthLayout/AuthLayout";
import InputMask from "antd-mask-input";
import { useHistory } from "react-router-dom";

export class CustomSignUp extends SignUp {
  _validAuthStates = ["signUp"];

  formRef = React.createRef<FormInstance>();
  loading = false;

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    this.inputs.password = val;

    this.handleInputChange(e);
  };

  goBackButton = () => {
    const history = useHistory();
    return (
      <Button
        onClick={(e) => {
          history.push("/");
          this.changeState("signIn");
        }}
        style={{ width: "100%", marginTop: 10 }}
        type="link"
      >
        Masz już konto? Zaloguj się tutaj
      </Button>
    );
  };

  signUpFields = [
    {
      label: "E-mail",
      key: "username",
      placeholder: "Wprowadź e-mail",
      required: true,
      displayOrder: 1,
    },
    {
      label: "Hasło",
      key: "password",
      placeholder: "Wprowadź hasło",
      required: true,
      displayOrder: 2,
    },
    {
      label: "Pin",
      key: "custom:pin",
      placeholder: "Wprowadź Pin",
      required: true,
      displayOrder: 3,
    },
  ];

  handleSubmit = (e: FormEvent) => {
    e.persist();
    e.preventDefault();
    this.formRef.current!.validateFields().then((values) => {
      super.signUp();
      this.loading = true;
    });
  };

  showComponent() {
    return (
      <AuthLayout>
        <Helmet>
          <title>Rejestracja | Profarmps.pl</title>
        </Helmet>
        <Typography.Title level={4} style={{ textAlign: "center" }}>
          Rejestracja nowego pacjenta
        </Typography.Title>
        <Typography.Paragraph type="secondary">
          Witaj w naszej aplikacji! Wpisz poniżej kod PIN otrzymany od lekarza i
          dane którymi chcesz logować się do swojego konta.
        </Typography.Paragraph>
        <Form
          onSubmitCapture={this.handleSubmit}
          ref={this.formRef}
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          hideRequiredMark
        >
          <Form.Item
            name="custom:pin"
            label="Kod PIN"
            rules={[
              {
                required: true,
                message: "PIN jest wymagany",
              },
              {
                whitespace: true,
                message: "PIN jest wymagany",
              },
            ]}
          >
            <InputMask
              mask="111111"
              id="custom:pin"
              key="custom:pin"
              name="custom:pin"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item
            name="username"
            label="E-mail"
            rules={[
              { type: "email", message: "To nie jest poprawny e-mail." },
              {
                required: true,
                message: "To pole jest wymagane.",
              },
              {
                whitespace: true,
                message: "To pole jest wymagane.",
              },
            ]}
          >
            <Input
              id="username"
              key="username"
              name="username"
              onChange={this.handleInputChange}
              placeholder="Wpisz adres e-mail, którego będziesz używać do logowania"
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Hasło"
            hasFeedback
            rules={[
              {
                required: true,
                message: "To pole jest wymagane.",
              },
              {
                whitespace: true,
                message: "To pole jest wymagane.",
              },
              {
                pattern: /\d/g,
                message: "Hasło musi zawierać cyfrę",
              },

              {
                pattern: /.{8,}/g,
                message: "Hasło musi mieć co najmniej 8 znaków",
              },
              {
                pattern: /[A-ZŻŹĆĄŚĘŁÓŃ]/,
                message: "Hasło musi zawierać wielką literę",
              },
              {
                pattern: /[a-zżźćąśęłóń]/,
                message: "Hasło musi zawierać małą literę",
              },
            ]}
          >
            <Input.Password
              id="password"
              key="password"
              name="password"
              onChange={this.handleChange}
              placeholder="Wpisz hasło, którego będziesz używać do logowania"
            />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label="Powtórz hasło"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Hasło jest wymagane.",
              },
              {
                validator: (rule, value) => {
                  return !value || value === this.inputs.password
                    ? Promise.resolve()
                    : Promise.reject("Hasła nie są takie same");
                },
              },
            ]}
          >
            <Input.Password
              id="confirm_password"
              key="confirm_password"
              name="confirm_password"
              placeholder="Wpisz ponownie hasło"
            />
          </Form.Item>

          <Button
            onClick={this.handleSubmit}
            loading={this.loading}
            type="primary"
            htmlType="submit"
            style={{ width: "100%", height: 40, marginTop: 20 }}
          >
            Zarejestruj
          </Button>
          <this.goBackButton />
        </Form>
      </AuthLayout>
    );
  }
}
