import useUserGroup from "hooks/useUserGroup";
import React, { Suspense, useMemo } from "react";
import { RouteProps } from "react-router-dom";

const AdminAndNCKAppLayout = React.lazy(() =>
  import("./AdminAndNCKAppLayout/AdminAndNCKAppLayout")
);
const DoctorAndPatientAppLayout = React.lazy(() =>
  import("./DoctorAndPatientAppLayout/DoctorAndPatientAppLayout")
);

interface Props extends RouteProps {}

export const AppLayout: React.FC<Props> = ({ children }) => {
  const userGroup = useUserGroup();

  const GroupComponent = useMemo(
    () =>
      userGroup &&
      {
        Admins: AdminAndNCKAppLayout,
        Doctors: DoctorAndPatientAppLayout,
        Patients: DoctorAndPatientAppLayout,
        nck: AdminAndNCKAppLayout,
      }[userGroup],
    [userGroup]
  );

  return (
    <div>
      <Suspense fallback={null}>
        {GroupComponent && <GroupComponent>{children}</GroupComponent>}
      </Suspense>
    </div>
  );
};

export default AppLayout;
