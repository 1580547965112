import { Auth, Hub } from "aws-amplify";
import React, { useEffect } from "react";
import { axiosClient } from "utils";

interface DosageUnitContextType {
  dosageUnits: DosageUnit[];
  setDosageUnits: (dosageUnits: any) => void;
}

export const DosageUnitsContext = React.createContext<DosageUnitContextType>({
  dosageUnits: [],
  setDosageUnits: (dosageUnits: DosageUnit[]) => {
    console.log("Not loaded yet!");
  },
});

export const DosageUnitsContextProvider: React.FC = ({ children }) => {
  const setDosageUnits = (dosageUnits: DosageUnit[]) => {
    setState({ ...state, dosageUnits });
  };

  const [state, setState] = React.useState<DosageUnitContextType>({
    dosageUnits: [],
    setDosageUnits,
  });

  useEffect(() => {
    const updateEntities = async () => {
      try {
        const res = await axiosClient.get("/dosage_units");
        setDosageUnits(res.data);
      } catch (err) {
        setDosageUnits([]);
      }
    };

    Auth.currentAuthenticatedUser().then((user) => {
      user && updateEntities();
    });

    Hub.listen("auth", updateEntities);
    return () => Hub.remove("auth", updateEntities);
  }, []);

  return (
    <DosageUnitsContext.Provider value={state}>
      {children}
    </DosageUnitsContext.Provider>
  );
};
