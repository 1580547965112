// insert user pool config here
export const awsconfig =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BACKEND === "prod"
      ? {
          aws_project_region: "eu-central-1",
          aws_cognito_region: "eu-central-1",
          aws_user_pools_id: "eu-central-1_1nuokm7lp",
          aws_user_pools_web_client_id: "7crdfnfkkjgjiamb3e5a1pg3c4",
        }
      : {
          aws_project_region: "us-east-1",
          aws_cognito_region: "us-east-1",
          aws_user_pools_id: "us-east-1_OyN9YRLKr",
          aws_user_pools_web_client_id: "5sk8d6insuu2f6ve2d96e9quca",
        }
    : process.env.NODE_ENV === "production"
    ? {
        aws_project_region: "eu-central-1",
        aws_cognito_region: "eu-central-1",
        aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
        aws_user_pools_web_client_id:
          process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
      }
    : {
        aws_project_region: "eu-central-1",
        aws_cognito_region: "eu-central-1",
        aws_user_pools_id: "",
        aws_user_pools_web_client_id: "",
      };

// REST endpoint
export const awsendpoint =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BACKEND === "prod"
      ? "https://rp7np1hsel.execute-api.eu-central-1.amazonaws.com/Prod"
      : "https://mt1reh6ea6.execute-api.us-east-1.amazonaws.com/Stage"
    : process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_ENDPOINT
    : "";

export const reportEndpoint =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BACKEND === "prod"
      ? "https://bc8azzot1i.execute-api.eu-central-1.amazonaws.com/Prod"
      : "https://9npfcazk30.execute-api.eu-central-1.amazonaws.com/Stage"
    : process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_REPORT_ENDPOINT
    : "";

export default awsconfig;
