import { Typography } from "antd";
import styles from "./AuthLayout.module.scss";
import React from "react";

export const AuthLayout: React.FC = ({ children }) => (
  <div className={styles["auth-layout"]}>
    <div className={styles["auth-wrapper"]}>
      <img src="./logopro.png" alt="" className={styles["auth-logo"]} />
      {children}
    </div>
  </div>
);

export default AuthLayout;
