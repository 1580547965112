import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";
import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

const DoctorID = React.lazy(() =>
  import("pages/Lekarze/[:DoctorID]/[:DoctorID]")
);
const DashboardPage = React.lazy(() => import("../pages/Dashboard/Dashboard"));
const LekarzePage = React.lazy(() => import("../pages/Lekarze/Lekarze"));
const PacjenciPage = React.lazy(() => import("../pages/Pacjenci/Pacjenci"));
const DostawyPage = React.lazy(() => import("../pages/Dostawy/Dostawy"));
const JednostkiPage = React.lazy(() => import("../pages/Jednostki/Jednostki"));
const PatientID = React.lazy(() =>
  import("../pages/Pacjenci/[:PatientID]/[:PatientID]")
);
const ZgloszeniaPage = React.lazy(() =>
  import("../pages/Zgloszenia/Zgloszenia")
);

export const routesConfig: {
  route: string;
  name: string;
  component: React.FC;
  showInNav: boolean;
}[] = [
  {
    route: "/",
    name: "Dashboard",
    component: DashboardPage,
    showInNav: true,
  },
  {
    route: "/lekarze",
    name: "Wykaz lekarzy",
    component: LekarzePage,
    showInNav: true,
  },
  {
    route: "/pacjenci",
    name: "Wykaz pacjentów",
    component: PacjenciPage,
    showInNav: true,
  },
  {
    route: "/zgloszenia",
    name: "Zgłoszenia",
    component: ZgloszeniaPage,
    showInNav: true,
  },
  {
    route: "/pacjenci/:id",
    name: "Zgłoszenia",
    component: PatientID,
    showInNav: false,
  },
  {
    route: "/lekarze/:id",
    name: "Zgłoszenia",
    component: DoctorID,
    showInNav: false,
  },
  {
    route: "/dostawy",
    name: "Dostawy",
    component: DostawyPage,
    showInNav: true,
  },
  {
    route: "/jednostki",
    name: "Jednostki",
    component: JednostkiPage,
    showInNav: true,
  },
];

export const Routes: React.FC = () => (
  <Suspense fallback={<LoadingPlaceholder />}>
    <Switch>
      {routesConfig.map((item) => (
        <Route
          key={item.route}
          exact
          path={item.route}
          component={item.component}
        />
      ))}
      <Redirect to="/" />
    </Switch>
  </Suspense>
);

export default routesConfig;
