import { Button, Form, Input, Typography, Row, Col } from "antd";
import { FormInstance } from "antd/lib/form";
import { SignIn } from "aws-amplify-react";
import React, { FormEvent } from "react";
import Helmet from "react-helmet";

import AuthLayout from "../layout/AuthLayout/AuthLayout";

export class CustomSignIn extends SignIn {
  _validAuthStates = ["signIn", "signedOut", "signedUp"];

  formRef = React.createRef<FormInstance>();

  state = {
    loading: false,
  };

  componentDidUpdate = () => {
    if (window.location.pathname === "/rejestracja") {
      this.changeState("signUp");
    }
  };

  handleSubmit = (e: FormEvent) => {
    e.persist();
    e.preventDefault();
    this.formRef
      .current!.validateFields()
      .then((values) => {
        this.setState((prev) => ({ ...prev, loading: true }));
        super
          .signIn(e)
          .then(() => {
            this.setState((prev) => ({ ...prev, loading: false }));
          })
          .catch((err) => {
            console.log(err);
            this.setState((prev) => ({ ...prev, loading: false }));
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showComponent() {
    return (
      <AuthLayout>
        <Helmet>
          <title>Logowanie | Profarmps.pl</title>
        </Helmet>
        <Typography.Title level={4} style={{ textAlign: "center" }}>
          Logowanie do aplikacji
        </Typography.Title>
        <Form
          onSubmitCapture={this.handleSubmit}
          ref={this.formRef}
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          hideRequiredMark
        >
          <Form.Item
            name="username"
            label="E-mail"
            rules={[
              { type: "email", message: "To nie jest poprawny e-mail." },
              {
                required: true,
                message: "E-mail nie może być pusty.",
              },
              {
                whitespace: true,
                message: "E-mail nie może być pusty.",
              },
            ]}
          >
            <Input
              id="username"
              key="username"
              name="username"
              onChange={this.handleInputChange}
              placeholder="Wpisz swój adres e-mail"
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Hasło"
            rules={[
              {
                required: true,
                message: "Hasło jest wymagane.",
              },
              {
                whitespace: true,
                message: "Hasło jest wymagane.",
              },
            ]}
          >
            <Input.Password
              id="password"
              key="password"
              name="password"
              onChange={this.handleInputChange}
              placeholder="Wpisz swoje hasło"
            />
          </Form.Item>
          <Button
            loading={this.state.loading}
            onClick={this.handleSubmit}
            type="primary"
            htmlType="submit"
            style={{ width: "100%", height: 40, marginTop: 20 }}
          >
            Zaloguj
          </Button>
          <Row style={{ marginTop: 10, marginBottom: -20 }}>
            <Col xs={24} sm={12}>
              <Button
                type="link"
                style={{
                  width: "100%",
                  height: "100%",
                  whiteSpace: "pre-wrap",
                }}
                onClick={(e) => this.changeState("forgotPassword")}
              >
                Zapomniałeś hasła?
              </Button>
            </Col>
            <Col xs={24} sm={12}>
              <Button
                onClick={(e) => {
                  this.changeState("signUp");
                }}
                style={{
                  width: "100%",
                  height: "100%",
                  whiteSpace: "pre-wrap",
                }}
                type="link"
              >
                Jesteś nowym pacjentem? Zarejestruj się tutaj
              </Button>
            </Col>
          </Row>
        </Form>
      </AuthLayout>
    );
  }
}
